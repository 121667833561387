<template>
    <div class="p-3 text-center">
        <b-img class="main-logo" src="/img/logo.png" alt="Cluji"/>
        <h1 class="mb-5 mt-3">Connexion</h1>
        <login :default-redirect="{name: 'mobile_app_home'}"/>
    </div>
</template>

<script>
    import Login from '@/components/Login';

    export default {
        name: "MobileLogin",
        components: {Login},
        mounted() {
            if (this.$route.query.token)
                this.$store.dispatch('mobile/setToken', this.$route.query.token);
        }
    }
</script>

<style scoped>
    img.main-logo {
        width: 50vw;
        filter: drop-shadow(0 0 0.25rem black);
    }
</style>
